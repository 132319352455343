<template>  
  <div id="app" class="app-container">
  <transition class='.fade-enter-active' name="fade">
      <router-view />
  </transition>
</div>
</template>

<style>

.fade-enter-active, .fade-leave-active {
  transition: opacity 5.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 5;
}

#app {
  font-family: Ubuntu, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #ffffff;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
} 

@font-face {
  font-family: "Ubuntu";
  src: local("Ubuntu"),
   url(./fonts/Ubuntu/Ubuntu-Light.ttf) format("truetype");
}

.description{
     font-family: "Ubuntu-Light";
     font-size: 16px;
  }
</style>

