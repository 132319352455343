<template class="header-section animate__animated animate__fadeInDown">
  <div class="row col-md-12 col-xs-12 mt-5 justify-content-center"  >
        <div class="col-xl-2 col-md-4">
            <div>
            <img src="/assets/img/logo_mitikuinRedondeado.png" class="img-fluid overflow-clip-margin: content-box my-4" alt="" width="120">
          </div>
          </div>
        </div>

      <div class="row justify-content-center"  >
        <div class="col-xl-6 col-lg-8">
          <h1>Mitikuin</h1>
          <h2>Software a la Medida</h2>
        </div>
      </div>
</template>

<script>
export default {
  name: 'HeaderMiti',
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
my-4 {
  margin-top: 0 rem !important;
}
</style>